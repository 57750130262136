import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueGtag from "vue-gtag";
import { BootstrapVue } from "bootstrap-vue";
import Vuelidate from "vuelidate";

import "@fontsource/roboto";
import "@fontsource/roboto/700.css";

import "./assets/scss/main.scss";

/* --- FontAwesome --- */
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faComment,
  faClipboardCheck,
  faQuestion,
  faArrowAltCircleUp,
  faExternalLinkAlt,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";

import {
  faHandshake,
  faClock,
  faCheckCircle
} from "@fortawesome/free-regular-svg-icons";

import {
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faComment,
  faClipboardCheck,
  faQuestion,
  faArrowAltCircleUp,
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
  faLinkedin,
  faExternalLinkAlt,
  faHandshake,
  faClock,
  faExclamationTriangle,
  faCheckCircle
);
Vue.component("font-awesome-icon", FontAwesomeIcon);
/* ------------------- */

Vue.use(BootstrapVue);
Vue.use(Vuelidate);

Vue.config.productionTip = false;

if (process.env.NODE_ENV == "production") {
  Vue.use(
    VueGtag,
    {
      config: { id: "G-TXZ6PGBDDZ" }
    },
    router
  );
} else {
  Vue.use(
    VueGtag,
    {
      config: { id: "G-1DH60LDZF1" }
    },
    router
  );
}

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
